import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Footer.module.css'
import FabButton from '../Fab/Fab'

const Footer = () => {
    const location = useLocation()
    const [user, setUser ] = useState(JSON.parse(localStorage.getItem('profile')))

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    return (
        <footer>
            <div className={styles.footerText}>
            © 2023 <span><a href="https://lsrenovation.fr" target="_blank" rel="noopener noreferrer">LSRenovation</a></span> | Made with ♥ in 🇫🇷
            </div>
            {user && (
            <FabButton />
            )}
        </footer>
    )
}

export default Footer

import React from 'react'
import moment from 'moment'
import { toCommas } from '../../utils/utils'
import styles from './InvoiceDetails.module.css'
import { InputBase } from '@material-ui/core'


const PaymentHistory = ({ paymentRecords}) => {

  
    return (
  <div className="tabs">
    <div className="tab">
      <input type="checkbox" id="chck1" />
      <label className="tab-label" htmlFor="chck1">Historique des paiements <span className={styles.totalUnpaid}>{paymentRecords?.length}</span><span className={styles.space}></span></label>
      <div className="tab-content">
       <div>
         <table>
           <tbody>
         <tr>
          <th>Date de paiement</th>
          <th>Montant payé</th>
          <th>Mode de paiement</th>
          <th>Note</th>
        </tr>
         {paymentRecords?.map((record) => (
          <tr key={record._id}>
            <td>{moment(record.datePaid).format('DD MMMM YYYY')}</td>
            <td>{toCommas(record.amountPaid)}</td>
            <td>{record.paymentMethod}</td>
            <td><InputBase style={{ width: '100%', outline: 'none', ml: 1, flex: 1 }} multiline value={record.note} readOnly /></td>
          </tr>

         ))}
         </tbody>
         </table>
       </div>
      </div>
    </div>
  </div>

    )
}

export default PaymentHistory
